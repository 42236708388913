<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
      <template slot="title">
        <div class="plugin-modal-header">
          <div class="plugin-info">
            <div class="plugin-img">
              <img :src="customizeIcon" alt="" />
            </div>
            <div class="plugin-name">
              <p>Custom Plugin</p>
              <span
                >Connect an OpenAI Plugin to expose Chat KeyReply to third-party applications.</span
              >
            </div>
          </div>
        </div>
      </template>
      <div class="plugin-modal-body">
        <div class="plugin-modal-configuration">
          <div>
            <p style="font-size: 16px; margin-bottom: 10px"><b>Plugin name</b></p>
            <el-input placeholder="Your Plugin name" v-model="tempConfig.name" />
          </div>
        </div>
        <div class="plugin-modal-configuration">
          <p style="font-size: 16px; margin-bottom: 10px"><b>Plugin File</b></p>
          <PluginUpload @updateFileUrl="handleUpdateFileUrl" :selectedPlugin="selectedPlugin" />
          <div v-if="tempConfig.fileUrl">
            <div>
              <p><b>JSON file URL</b></p>
            </div>
            <el-input v-model="tempConfig.fileUrl" disabled />
          </div>
        </div>
      </div>
      <template slot="footer">
        <div>
          <el-button type="plain" @click="handleClose">Cancel</el-button>
          <el-button type="primary" @click="handleConfirm">Confirm</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import customizeIcon from "./assets/icons/customize.png";
import PluginUpload from "./PluginUpload.vue";

export default {
  components: {
    PluginUpload,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    selectedPlugin: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      customizeIcon,
      tempConfig: {
        name: "",
        fileUrl: "",
      },
    };
  },
  methods: {
    handleClose() {
      this.$emit("updateDialogVisible", false);
    },
    handleUpdateFileUrl(fileUrl) {
      this.tempConfig.fileUrl = fileUrl;
    },
    handleConfirm() {
      this.$confirm("Do you want to save the current settings ?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "warning",
      }).then(() => {
        this.$emit("updatePluginConfig", this.tempConfig);
        this.$success("Settings saved successfully");
      });
    },
  },
  created() {
    if (this.selectedPlugin) {
      this.tempConfig = this.selectedPlugin.config;
    }
  },
};
</script>

<style lang="scss">
@import "./assets/css/modal.scss";
</style>
